<template>
    <div class="card">   
      <form
        action
        class="form white"
        @submit.prevent="submit"
      >
        <img 
          src="../assets/LogoTVF.png"
          class="logo pb-4"
          width="175" 
          height="auto" 
        /> 
        <div class="py-4 mb-10" style="border: 1px solid #fff; border-radius: 5px;background-color: #F8F9FB;">
          <v-col
            class="pl-8"
            cols="12"
            sm="12"
            md="12"
          >
            <div class="pb-4">
              <label class="caption font-weight-bold">Subject</label><br />
              <span class="caption">{{ params.param1 }}</span><br />
            </div>
            <div class="pb-4">
              <label class="caption font-weight-bold">Sender</label><br />
              <span class="caption">{{ params.param1 }}</span><br />
            </div>
            <div class="pb-4">
              <label class="caption font-weight-bold">Database</label><br />
              <span class="caption">{{ params.param1 }}</span><br />
            </div>            
            <div class="d-flex pb-2">
              <div class="pr-16">
                <label class="caption font-weight-bold">Send date</label><br />
                <span class="caption">{{ params.param2 }}</span><br />
              </div>
              <div class="pl-16 pb-2">
                <label class="caption font-weight-bold">Volume</label><br />
                <span class="caption">{{ params.param2 }}</span><br />
              </div>              
            </div>  
            <div class="d-flex pb-2">
              <div class="pr-16">
                <label class="caption font-weight-bold">Paymodel</label><br />
                <span class="caption">{{ params.param2 }}</span><br />
              </div>
              <div class="pl-16">
                <label class="caption font-weight-bold">Payout</label><br />
                <span class="caption">{{ params.param2 }}</span><br />
              </div>              
            </div>                   
          </v-col>
        </div>
        <v-row>
          <v-col class="" cols="12">
            <v-btn
              @click="showHtml"
              class="white--text"
              color="#000"
              large
              block
            >
              Preview html
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="" cols="6">
            <v-btn
              type="submit"
              class="white--text"
              color="#8A0F48"
              large
              block
            >
              Validate Bat
            </v-btn>
          </v-col>
          <v-col cols="6" >
            <v-btn
              @click="refuse"
              class="white--text"
              color="#FF005B"
              large
              block
            >
              Refuse Bat
            </v-btn>
          </v-col>
        </v-row>
        
      </form>       
    </div>  
  </template>
  
  <script>
  
  export default {
    name: "validation",
    data: () => ({
      error: false,
      params: []
    }),

    created () {
      this.params = this.$route.query
      console.log(this.params)  // Aquí puedes usar los parámetros recibidos desde la URL
    },
  
    computed: {

    },
  
    methods: {
  
      submit() {
         
      },

      refuse() {

      },

      showHtml() {
        this.html = '<h3>hola</h3>';/* this.params.html; */
        const nuevaVentana = window.open();
        nuevaVentana.document.title = "PREVIEW";
        nuevaVentana.document.write(
          '<div style="background-color: #F85182; color: white; padding: 3px 10px 3px 10px; margin-bottom: 10px"><h3 style="text-align: center;">PREVIEW</h3></div>'
        );
        nuevaVentana.document.write(this.html);
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .card {
    padding: 2rem;
  }
  .logo{
      margin: 1.5rem auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
  }
  .form {
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    min-width: 30%;
    max-width: 100%;
    background: rgba(19, 35, 47, 0.9);
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 1600px) {
    .form {
      min-width: 45%;
      max-width: 100%;
    }
  }
    
  </style>